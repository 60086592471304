import React from 'react'
import Header from './header'
import Footer from './footer'
import Footer1 from './footer1'

const Contact_Us = () => {
  return (
    <section className='Contact_Us'>
      <Header color="black"/>
      
      <Footer1 />
      <Footer />
    </section>
  )
}

export default Contact_Us
